<template>
  <div class="page-list personnelfiles flex-1">
    <a-row class="filter-wrap  ">
      <a-col
        :lg="8"
        :md="24"
        :sm="24"
        :xs="24"
        style="margin-left:0px;"
        class="mgb-20"
      >
        <a-button
          type="primary"
          class="table-btn-clk"
          style="margin-right:19px;margin-left: 0px;"
          @click="addRules"
        >添加公司</a-button>
      </a-col>
      <a-col
        :lg="16"
        :md="24"
        :sm="24"
        :xs="24"
        class="text-right flex align-item-center j-end "
      >
        <span style="margin-right:-21px;">选择上级公司：</span>
        <a-tree-select
          :allowClear="true"
          class="mgl-20 mgr-20 pgb-20 "
          v-model="query.company_parent"
          :dropdown-match-select-width="true"
          style="width: 256px;"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData"
          placeholder="请选择上级公司"
          :replaceFields="selectreeObj"
        >
        </a-tree-select>

        <a-button
          class="mgl-20 table-btn-clk"
          type="primary"
          style="height:32px;margin-top:1px;"
          @click="getList()"
        >查询</a-button>
      </a-col>
    </a-row>
    <a-table
      class="table-manage flex-1"
      @change="tableChanged"
      :pagination="table.pagination"
      row-key="company_id"
      :loading="table.loading"
      :columns="config.list.columns"
      :data-source="table.tableData"
    >
      <template
        slot="_2"
        slot-scope="action, record"
      >
        <a-tooltip>
          <template slot="title">
            {{  record.company_name}}
          </template>
          {{  record.company_name_short || '公司简称'}}
        </a-tooltip>
      </template>
      <template
        slot="_3"
        slot-scope="action, record"
      >
        <img
          style="width:20px;height:20px;"
          :src="record.company_logo"
          alt=""
          @error="$utils.imageOnerror($event)"
        >
      </template>
      <template
        slot="_5"
        slot-scope="action, record"
      >
        <span>{{ record.company_boss_name || '--' }}</span>
        <span>{{ record.company_boss_phone || '--' }}</span>
      </template>
      <template
        slot="_7"
        slot-scope="action, record"
      >
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="addRules(record,3)"
        >编辑</a-button>
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="handleBatch('delete',[record],'提示')"
        >删除</a-button>
      </template>
    </a-table>
    <!-- 编辑添加身份 -->
    <a-modal
      :visible="permissionInfo.visible"
      width="750px"
      wrapClassName="commonModel"
      @cancel="permissionInfo.visible=false"
      :okText="permissionInfo.text"
      @ok="okclickmod"
      destroyOnClose
      :bodyStyle="{height: '258px'}"
    >
      <div class="constmodel">
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"><span class="imgxing">*</span>公司名称：</div>
              <div class="rgbox">
                <a-input
                  v-model="permissionInfo.info.company_name"
                  placeholder="请输入公司名称"
                ></a-input>
              </div>
            </div>
          </a-col>
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox">公司简称：</div>
              <div class="rgbox">
                <a-input
                  v-model="permissionInfo.info.company_name_short"
                  placeholder="请输入公司简称"
                ></a-input>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox">负责人：</div>
              <div class="rgbox">
                <a-input
                  v-model="permissionInfo.info.company_boss_name"
                  placeholder="请输入负责人"
                ></a-input>
              </div>
            </div>
          </a-col>

          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox">负责人电话：</div>
              <div class="rgbox">
                <a-input
                  v-model="permissionInfo.info.company_boss_phone"
                  placeholder="请输入负责人电话"
                ></a-input>
              </div>
            </div>
          </a-col>

        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"> 上级公司 </div>
              <div class="rgbox">
                <a-tree-select
                  :disabled="permissionInfo.isDisabled"
                  class=" pgb-20 width-min-164"
                  v-model="permissionInfo.info.company_parent"
                  style="width: 196px;"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="treeData"
                  placeholder="请选择上级公司"
                  :replaceFields="selectreeObj"
                >
                </a-tree-select>
              </div>
            </div>
          </a-col>
          <a-col>
            <div
              class="linebox flex"
              style="align-items: flex-start;"
            >
              <div class="lfbox">公司logo:</div>
              <div
                class="rgbox"
                style="width: 204px;"
              >
                <a-upload
                  name="avatar"
                  list-type="picture-card"
                  class="avatar-uploader"
                  :show-upload-list="false"
                  :action="baseUrl+'/upload'"
                  :headers="headers"
                  :before-upload="beforeUpload"
                  :customRequest="uploadFiles"
                  @change="handleChange"
                  style=""
                >
                  <img
                    v-if="permissionInfo.info.company_logo"
                    :src="permissionInfo.info.company_logo"
                    alt="avatar"
                  />
                  <div v-else>
                    <a-icon :type="permissionInfo.pic_url_loading ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
              </div>
            </div>

          </a-col>
        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"><span class="imgxing">*</span> 公司状态</div>
              <div class="rgbox">
                <a-radio-group v-model="permissionInfo.info.company_status">
                  <a-radio :value="1">
                    审核中
                  </a-radio>
                  <a-radio :value="2">
                    正常
                  </a-radio>
                  <a-radio :value="3">
                    审核失败
                  </a-radio>
                  <a-radio :value="4">
                    已禁用
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </a-col>
        </a-row>

      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span class="titmodel">{{ permissionInfo.title }}</span>
        </div>
      </template>
    </a-modal>
  </div>

</template>
<script> 
import storage from 'store'
import { GetCompanylist, CompanyoptionList, Createcompany, Updatecompany, CompanyInfo } from '@/api/permission'
import config from './config'
import axios from 'axios'
export default {
  components: {},
  data() {
    return {
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        token: storage.get('token'),
        "Content-Type": 'multipart/form-data'
      },
      coptionsList: [],
      query: {
        company_parent: undefined,
        per_page: 10,
        page: 1,
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          company_id: undefined,
          company_name: undefined,
          company_name_short: undefined,//简称
          company_logo: undefined,
          company_boss_name: undefined,
          company_parent: undefined,
          company_boss_phone: undefined,
          company_status: 2, //1审核中、2正常、3审核失败、4已禁用
        }
      },
      treeData: [],
      selectreeObj: {
        children: 'children',
        title: 'company_name',
        key: 'company_id',
        value: 'company_id'
      },
    }
  },
  async created() {
    this.getCompanyoptionList()
    // this.getList()

  },
  methods: {
    handleMenuClick({ key }) {
      this.handleBatch(key)
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        const { file_url } = info.file.response.data
        this.permissionInfo.info.permission_icon = file_url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    // 删除 
    handleBatch(key, record, title = "删除") {
      let self = this
      let deleteRules = ''
      const urlArr = [
        { name: deleteRules, key: 'delete', okType: 'primary', msg: '确定要删除吗？删除后将无法恢复，请谨慎操作！' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        closable: true,
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            employee_ids: selectedRowKeys,
          };
          (activeObj.name)(data).then(res => {
            self.getList()
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          company_parent: self.query.company_parent
        },
        page: self.query.page,
        per_page: self.query.per_page
      }

      return GetCompanylist(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.per_page, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 公司树形列表
    getCompanyoptionList(e) {
      CompanyoptionList().then(res => {
        this.treeRecursion(res.data)
        this.treeData = res.data
        console.log(this.treeData, '>>>this.coptionsList');
        if (!e) {
          // this.query.company_parent = res.data[0].company_id
          // this.permissionInfo.info.company_parent = res.data[0].company_id
          this.getList()
        }

      })
    },

    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { per_page: Number(pageSize), page: Number(current) })
      this.getList()
    },

    changeStatus($event, e) {
      let obj = {}
      this.table.tableData.forEach(el => {
        if (e.role_id == el.role_id) {
          el.role_status = $event ? 1 : 0
          obj = el
        }
      })
      this.permissionInfo.type = 3 //编辑
      this.permissionInfo.info = {
        role_id: obj.role_id,
        // system_id: obj.system_id,
        role_name: obj.permission_name,
        permission_id_list: [],
        role_status: obj.role_status
      }
      this.okclickmod()
    },
    onChangePermissiontype(e) {
      console.log('radio checked', e.target.value, this.permissionInfo.info.permission_type);
    },
    // 提交按钮
    okclickmod(e) {
      let data = {
        ...this.permissionInfo.info
      }
      if (this.permissionInfo.type == 3) {
        delete data.created_at
        delete data.updated_at
        delete data.system_id
        Updatecompany(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getCompanyoptionList()
          }
        })
      } else {
        Createcompany(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getCompanyoptionList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {
      this.permissionInfo.isDisabled = false
      this.permissionInfo.info = {
        company_id: undefined,
        company_name: undefined,
        company_name_short: undefined,//简称
        company_logo: undefined,
        company_boss_name: undefined,
        company_boss_phone: undefined,
        company_status: 2,
      }
      await this.getCompanyoptionList(true)
      console.log(this.treeData);
      this.permissionInfo.visible = true
      if (i == 3) {
        this.permissionInfo.isDisabled = true
        this.permissionInfo.title = '编辑公司'
        this.permissionInfo.info.company_id = e.company_id
        this.permissionInfo.type = 3
        this.openDatails()
        console.log(this.permissionInfo.info);
      } else {
        this.permissionInfo.title = '添加公司'
        this.permissionInfo.type = 1
        delete this.permissionInfo.info.company_id
        console.log(this.permissionInfo);

      }
    },

    openDatails() {
      CompanyInfo({ company_id: this.permissionInfo.info.company_id }).then(res => {
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
      })
    },


    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.company_id) == 'number') {
          el.company_id = el.company_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      console.log(props)
      // if(props.record.children.length > 0){
      if (props.expanded) {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">关闭详情</a-button></a>
      } else {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">查看详情</a-button></a>
      }
      // }else{
      //     return <span style={{marginRight:8 }}></span>
      // }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.company_logo = res.data.url
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

  },
  watch: {
    checkedKeys(val) {
      console.log('onCheck', val);
    },
  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 20px 12px 20px 20px;
  overflow: hidden;
  // background: #fff;
  // height: 100%;
  // min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0 ;
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 20px;
  height: 18px;
  background: @btn2mianbgDColor;
  box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
    inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  width: 100px;
  height: 18px;
  display: inline-block;
  line-height: 18px;
  .borderbox {
    position: absolute;
    top: 0;
    left: 29px;
    i {
      font-size: 8px;
      margin-top: -1px;
      transform: scale(0.83);
      display: inline-block;
      color: @btn2mianfontDColor;
    }
    &:hover {
      border: 1px solid @btn2mianboderHColor;
      i {
        color: @btn2mianfontHColor;
      }
    }
  }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  padding-left: 24px;
}
/deep/.ant-modal-footer {
  padding-bottom: 32px;
}
.btn-padding {
  display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  margin-top: -23px;
}
/deep/ .borderbox.ant-dropdown-open {
  border-color: @baseColor35 !important;
  color: @baseColor35 !important;
  i {
    color: @baseColor35 !important;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}
/deep/ .ant-table-body {
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
      color: red;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 45px !important;
  height: 45px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
</style>